import HomePage from '../components/homePage';
import Layout from '../components/layout';

const Home = () => (
    <Layout title="Fusion Home">
        <HomePage />
    </Layout>
);

export default Home;
